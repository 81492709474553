<template>
  <span
    v-b-tooltip.topleft.v-secondary.noninteractive="{
      title: `${tooltip_text ? tooltip_text : ''}`,
      boundary: 'viewport',
    }"
  >
    <b-button
      size="sm"
      :variant="variant"
      :title="text_button"
      @click="handleClick"
      class="noprint p-0"
      :class="custom_class"
      :disabled="disabled_button"
    >
      <b-icon-plus-square v-if="icon == 'plus'"></b-icon-plus-square>
      <b-icon-pencil-square v-else-if="icon == 'square'"></b-icon-pencil-square>
      <b-icon-trash v-else-if="icon == 'trash'" variant="danger"></b-icon-trash>
      <!-- <b-icon-arrow-down-up v-else-if="icon == 'arrow-down-up'"></b-icon-arrow-down-up> -->
      <b-icon-eye v-else-if="icon == 'eye'"></b-icon-eye>
      <b-icon-eye-slash v-else-if="icon == 'eye-slash'"></b-icon-eye-slash>
      <b-icon-book v-else-if="icon == 'book'"></b-icon-book>
      <b-icon-eraser v-else-if="icon == 'clean'"></b-icon-eraser>
      <slot name="icon"></slot>
      <span v-if="text_button" class="">
        {{ text_button }}
      </span>
    </b-button>
  </span>
</template>

<script>
export default {
  name: "ButtonSmall",
  props: {
    text_button: {
      type: String,
      required: false,
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    click_button: {
      type: Function,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    variant: {
      type: String,
      default: "none",
    },
    icon: {
      type: String,
      default: "",
      validator: (value) =>
        [
          "plus",
          "square",
          "trash",
          //   "arrow-down-up",
          "eye",
          "eye-slash",
          "book",
          "clean",
          "",
        ].includes(value),
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick() {
      // Si se proporciona una función de click, se ejecuta
      if (!this.disabled_button) {
        if (this.click_button) {
          this.click_button();
        } else {
          this.$emit("click");
        }
      }
    },
  },
};
</script>

<style scoped>
button:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
}
</style>